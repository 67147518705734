.loading-page {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #a7a7a78f;
  img {
    width: 120px;
  }
}
