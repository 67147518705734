$white: #ffffff;
$dark: #333333;
$primaryColor: #3F5896;
$textColor: #3B404A;
$borderColor: #D0D4DC;
$manatee: #8F96A5;
$red: #EB5757;
$greyOpacity: 'rgba(0;0;0;0.3)';
$primaryColor05: 'rgba(63;88;150;0.15)';
$backgroundLogin: #f6f5f7;
$brickRed: #ff4b2b;
$pinkLotus: #ff416c;
$revell: #eee;
$realRed: red;
