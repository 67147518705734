@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "PF-BeauSans-Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: "PF-BeauSans-Pro", sans-serif !important;
}

/* .slide-menu>.ant-layout-sider-children {
  position: fixed !important;
  width: 200px;
} */

.ant-modal-footer > .ant-btn-primary {
  background-color: #1677ff !important;
}
.ant-modal-footer > .ant-btn-primary:hover {
  background-color: #1677ffc2 !important;
}
.ant-modal-confirm-btns > .ant-btn-primary {
  background-color: #1677ff !important;
}
.ant-modal-confirm-btns > .ant-btn-primary:hover {
  background-color: #1677ffc2 !important;
}
:where(.css-dev-only-do-not-override-ixblex).ant-menu-dark
  .ant-menu-item-selected {
  background-color: #1677ff3d !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
}
.ant-table-cell > p {
  overflow-wrap: anywhere;
}
.switch-label-center > .ant-form-item-row > .ant-form-item-label {
  text-align: center;
}
.h-26 {
  height: 102px;
}
/* Start Scroll */

.scrollbar {
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar {
  width: 6px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #00000066;
  border-radius: 10px;
}

.ant-table-body::-webkit-scrollbar {
  width: 6px;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #00000066;
  border-radius: 10px;
}
/* End Scroll */

.ant-collapse-borderless
  > .vmc-search
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 !important;
}
.ant-switch-checked {
  background-color: rgb(38 198 97) !important;
}


/* Start Table */
.ant-table-tbody{
  /* padding: 8px !important; */
}
.ant-table-tbody > tr > td{
  padding: 8px !important;
  /* height: 150px; */
}
/* End Table */
/* This domain is not registered with Tiny Cloud. Please review */
/* .tox.tox-silver-sink.tox-tinymce-aux {
  display: none !important;
} */

@font-face {
  font-family: 'ArchivoLight';
  src: url('../public/fonts/Archivo-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Archivo';
  src: url('../public/fonts/Archivo.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'ArchivoBold';
  src: url('../public/fonts/Archivo-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'ArchivoExtraBold';
  src: url('../public/fonts/Archivo-ExtraBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'InterLight';
  src: url('../public/fonts/Inter-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'InterBold';
  src: url('../public/fonts/Inter-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'PF-BeauSans-Pro';
  src: url('../public/fonts/FS-PFBeauSansPro-Bbook.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun-bold';
  src: url('../public/fonts/Sarabun-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Sarabun-light';
  src: url('../public/fonts/Sarabun-Light.ttf');
  font-display: swap;
}